
import {
    defineComponent,
    onMounted,
    ref,
    reactive,
    nextTick,
    h,
    computed,
    watch,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { updateLatestDetail } from '@/API/localRegistration';
import Title from '../../components/title.vue';
import moment from 'moment';
import { Modal, message } from 'ant-design-vue';
export default defineComponent({
    components: {
        Title: Title,
    },
    setup() {
        const columns = [
            {
                title: 'No.',
                customRender: (text: any) => {
                    return text.index + 1;
                },
            },
            {
                title: 'Dealer/SWT code',
                dataIndex: 'dealerCode',
                key: 'dealerCode',
            },
            {
                title: 'Dealer Name',
                dataIndex: 'dealerName',
                key: 'dealerName',
            },
            {
                title: 'Province Ⅱ',
                dataIndex: 'province',
                key: 'province',
            },
            {
                title: 'Covered part Ⅱ area',
                dataIndex: 'city',
                key: 'city',
            },
            {
                title: 'Operation',
                dataIndex: 'operation',
                key: 'operation',
                slots: { customRender: 'operation' },
            },
        ];

        const route: any = useRoute();
        const router: any = useRouter();
        const dealerName = ref<string>(route.query.dealerName);
        const city = ref<string>(route.query.city);
        const dealerCode = ref<string>(route.query.dealerCode);
        const tableData = ref<any[]>(JSON.parse(route.query.tableData));
        const checkList = ref<string[]>(JSON.parse(route.query.checkList));
        const effectiveTime = ref<any>();
        tableData.value.forEach((item) => {
            item.isView = false;
            item.isDelete = false;
        });

        const handleView = (record: any) => {
            record.isView = true;
        };
        const handleDelete = (record: any) => {
            record.isDelete = true;
        };
        const handleDeleteAll = () => {
            tableData.value.forEach((item) => {
                item.isDelete = true;
            });
        };

        const handleSave = () => {
            const statusList = tableData.value.filter((item) => !item.isDelete);
            if (statusList.length > 0) {
                return message.error('You haven‘t finished deleting');
            }
            const data = {
                applicableTypeClass: checkList.value,
                effectiveTime:
                    moment(effectiveTime.value).format('YYYY-MM-DD') ===
                    'Invalid date'
                        ? null
                        : moment(effectiveTime.value).format('YYYY-MM-DD'),
                status: 0,
            };
            updateLatestDetail(data as any).then((res) => {
                message.success('You have added dealer successfully');
            });
        };

        const handleCancel = () => {
            router.push(
                '/localRegistration/focusAreaSet/dealerAdjustment/edit'
            );
        };
        return {
            columns,
            dealerName,
            city,
            dealerCode,
            tableData,
            effectiveTime,
            handleView,
            handleDelete,
            handleDeleteAll,
            handleSave,
            handleCancel,
        };
    },
});
